<template>
  <div class="login">
    <div class="form-section">
      <img src="/images/logo.svg" alt="logo" class="logo">

      <div class="greetings-title">
        Добро пожаловать
      </div>
      <div class="greetings">
        Рады видеть вас снова, пожалуйста заполните форму.
      </div>

      <form class="form">
        <div class="input-wrapper">
          <rcc-input
            label="E-mail"
            placeholder="Введите свой E-mail"
            v-model="login"
          />
        </div>

        <div class="input-wrapper">
          <rcc-input
            label="Пароль"
            placeholder="Введите свой пароль"
            v-model="password"
            :type="type"
            :append-icon="icon"
            @click:append="handleToggleShowPasswordClick"
          />
        </div>

        <rcc-button @click="onSubmit">
          Войти
        </rcc-button>

        <label class="help-desk">
          Забыли пароль - напишите нам <a :href="'mailto:'+helpDesk">{{ helpDesk }}</a>
        </label>
      </form>
    </div>

    <img src="/images/map.svg" alt="map" class="map">
    <img src="/images/store.svg" alt="store" class="store">
  </div>
</template>

<script>
import RccInput from '@/components/login-page/input'
import RccButton from '@/components/login-page/button'

export default {
  store: ['user'],

  components: {
    RccInput,
    RccButton
  },

  data () {
    return {
      helpDesk: 'rp@rcc-tara.ru',
      login: '',
      password: '',
      isShowPassword: false
    }
  },

  computed: {
    type () {
      return this.isShowPassword ? 'text' : 'password'
    },

    icon () {
      return this.isShowPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
    }
  },

  methods: {
    handleToggleShowPasswordClick () {
      this.isShowPassword = !this.isShowPassword
    },

    onSubmit () {
      if (this.login && this.password) {
        this.$axios.post('login', { login: this.login, password: this.password })
          .then(response => {
            const { access, refresh } = response.data
            this.user.set_tokens(access, refresh)
            this.$router.replace(this.$route.query.redirect || '/')
          })
          .catch(err => {
            console.error(err)
            if (err.response && err.response.data.message) {
              this.$message(err.response.data.message)
            }
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  padding: 118px;
  background-color: #25509A;
  min-height: 100vh;
  width: 100%;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  position: relative;
  z-index: 0;

  @media (max-width: 768px) {
    padding: 30px;
  }

  .logo {
    margin-left: -10px;
  }

  .greetings-title {
    font-weight: 600;
    font-size: 24px;
    margin-top: 50px;
    margin-bottom: 10px;
  }

  .greetings {
    font-size: 14px;
    margin-bottom: 40px;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 460px;
  }

  .help-desk {
    a {
      color: #62a9ff;
    }
  }

  .map {
    position: absolute;
    height: 90vh;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.2;
    z-index: -1;
  }

  .store {
    position: fixed;
    object-fit: contain;
    right: 0;
    bottom: 0;
    max-height: calc(100% - 100px);
    min-height: 500px;
    min-width: 600px;
    width: calc(100% - 700px);
    object-position: right bottom;
    z-index: -1;

    @media (max-width: 768px) {
      display: none;
    }
  }
}
</style>
