<template>
  <label class="input-wrapper">
    <div class="label">
      {{ label }}
    </div>

    <v-text-field
      v-bind="$attrs"
      v-on="$listeners"
      solo
      class="input"
      hide-details
    />
  </label>
</template>

<script>
export default {
  name: 'RccInput',

  inheritAttrs: false,

  props: {
    label: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.input-wrapper {
  .label {
    margin-bottom: 5px;
  }

  .v-input {
    border-radius: 6px;
  }

  ::v-deep .v-input__slot {
    background: initial !important;
    box-shadow: initial !important;
    border: 2px solid #ffffff;
    padding: 7px 12px !important;

    .v-icon {
      color: #ffffff;
    }

    input {
      color: #ffffff;
      font-size: 14px;

      &::placeholder {
        color: #ffffff;
      }
    }
  }
}
</style>