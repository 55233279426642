<template>
  <v-btn
    class="button"
    :type="type"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: 'RccButton',

  inheritAttrs: false,

  props: {
    type: {
      type: String,
      default: 'button'
    }
  }
}
</script>

<style lang="scss" scoped>
button {
  padding: 25px 10px !important;

  ::v-deep .v-btn__content {
    font-weight: 600;
    color: #25509A;
    text-transform: initial;
    font-size: 16px;
    letter-spacing: initial;
  }
}
</style>
